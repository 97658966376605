<template>
  <div class="text-g7-blue">
    <HomeHeroSlider class="mt-2 sm:mt-4 lg:mt-auto" />
    <div v-if="response" class="flex items-center justify-between">
      <Headline
        :h="1"
        class="m-4"
        size="3XL"
        :title="response.cruise_line_details.name"
      />
      <CloudinaryImg
        :public-id="response.cruise_line_details.logo.public_id"
        class="m-5 h-5 sm:h-8"
        :steps="[640, 1024]"
      />
    </div>

    <hr class="border-2 drop-shadow-lg" />

    <div class="flex flex-col lg:flex-row">
      <div
        v-if="expert"
        class="m-4 flex flex-col items-center lg:order-2 lg:items-start"
      >
        <Headline class="mb-2 text-center lg:mb-4" :title="expert.title" />
        <ExpertCard :expert="expert.expert" />
      </div>
      <div v-if="response" class="m-4 lg:order-1">
        <FieldParagraph
          :title="response.cruise_line_details.motto"
          :text="response.cruise_line_details.text"
        />
      </div>
    </div>

    <hr class="hidden border-2 md:block" />

    <CardGroup
      v-if="
        response &&
        response.cruise_line_details.detail_infos.details.length != 0
      "
      v-slot="{ item }"
      :mobile-slider="true"
      :title="response.cruise_line_details.detail_infos.title"
      :items="response.cruise_line_details.detail_infos.details"
    >
      <Infocard class="mx-8 h-min sm:h-full md:mx-0" :item="item" />
    </CardGroup>

    <Calendar
      v-if="response && response.cruise_calendar"
      class="m-4"
      :calendar="response.cruise_calendar"
    />

    <CruiselineClub
      v-if="response && response.cruise_club"
      class="m-4"
      :club="response.cruise_club"
    />

    <CardGroup
      v-if="ships"
      v-slot="{ item }"
      cols="4"
      :mobile-slider="true"
      :title="ships.title"
      :limit="8"
      :steps="8"
      :items="ships.ships"
      :button="ships.action_button.label"
    >
      <ImgCard class="mx-8 md:mx-0" img-clickable :item="item" />
    </CardGroup>

    <hr class="hidden border-2 md:block" />

    <CruiseList
      v-if="cruises"
      class="mx-4 mb-8"
      :title="cruises.title"
      :button="cruises.action_button"
      :offers="cruises.cruises"
    />

    <hr class="hidden border-2 md:block" />

    <div v-if="response" class="m-4 hidden justify-end md:flex">
      <a
        v-if="response.agb_link.context.startsWith('/media')"
        target="_blank"
        :href="response.agb_link.context"
      >
        <Headline
          class="cursor-pointer underline"
          :title="response.agb_link.label"
        />
      </a>
      <NuxtLink v-else :to="response.agb_link.context">
        <Headline
          class="cursor-pointer underline"
          :title="response.agb_link.label"
        />
      </NuxtLink>
    </div>
  </div>
</template>

<script lang="ts" setup>
import type { Links } from "~~/types/layout";
import type {
  CruiselineExpertResponse,
  CruiselineResponse,
  CruiselineShipsResponse,
  CruiseListResponse,
} from "~~/types/response";

const props = defineProps<{
  links: Links;
}>();

const { data: response, error } = await useGo7seasApi<CruiselineResponse>(
  props.links.self.href
);

if (!error.value) {
  useDiaShow().set(response.value?.dia_show.dias ?? []);
  updateDefaultLayout(response.value);
}

const { data: cruises } = await useGo7seasApi<CruiseListResponse>(
  props.links.cruises.href
);

const { data: ships } = await useGo7seasApi<CruiselineShipsResponse>(
  props.links.ships.href
);

const { data: expert } = await useGo7seasApi<CruiselineExpertResponse>(
  props.links.experts.href
);
</script>
