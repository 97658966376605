<template>
  <div class="flex justify-center">
    <div
      class="flex w-fit flex-col items-center justify-center sm:w-auto sm:flex-row sm:flex-wrap"
    >
      <FieldDropdownByValue
        :model-value="modelValue.adults"
        :options="options.adults_select.options"
        class="my-1 w-full whitespace-nowrap bg-white sm:w-fit"
        @update:model-value="update('adults', $event)"
      />
      <FieldDropdownByValue
        :model-value="modelValue.children[0]"
        :class="{ block: visibleChildren >= 1, hidden: visibleChildren < 1 }"
        :options="options.first_child_age_select.options"
        class="my-1 w-full whitespace-nowrap bg-white sm:ml-4 sm:w-fit lg:block"
        @update:model-value="updateChildren(0, $event)"
      />
      <FieldDropdownByValue
        :model-value="modelValue.children[1]"
        :class="{ block: visibleChildren >= 2, hidden: visibleChildren < 2 }"
        :options="options.second_child_age_select.options"
        class="my-1 w-full whitespace-nowrap bg-white sm:ml-4 sm:w-fit lg:block"
        @update:model-value="updateChildren(1, $event)"
      />
      <span
        v-if="hasErrors"
        class="my-1 block text-center text-xs text-red-500 md:text-sm"
        v-html="'Bitte das Alter der Kinder angeben.'"
      />
      <div class="flex h-field items-center lg:hidden">
        <div
          :class="{ hidden: visibleChildren === 2 }"
          class="my-1 flex size-6 cursor-pointer items-center justify-center rounded-full border border-black sm:ml-4 md:size-7"
          @click="showChilds(1)"
        >
          <IconsPlus class="size-4 fill-g7-blue md:size-5" />
        </div>

        <div
          :class="{
            hidden: visibleChildren === 0,
            'ml-4': visibleChildren < 2,
          }"
          class="my-1 flex size-6 cursor-pointer items-center justify-center rounded-full border border-black sm:ml-4 md:size-7"
          @click="showChilds(-1)"
        >
          <IconsMinus class="size-4 fill-g7-blue md:size-5" />
        </div>
      </div>
      <div
        v-if="options.cabin_types_select || modelValue.loyalty_program_name"
        class="flex w-full flex-col flex-wrap items-center justify-center sm:w-auto sm:flex-row lg:my-1"
      >
        <div
          v-if="modelValue.loyalty_program_name"
          class="flex items-center bg-white p-2 lg:ml-4"
        >
          <span
            v-if="!modelValue.loyalty_program_member"
            class="whitespace-nowrap uppercase text-g7-blue sm:ml-4"
          >
            {{ modelValue.loyalty_program_name }}
          </span>
          <FieldGenericInput
            v-if="modelValue.loyalty_program_member"
            :model-value="modelValue.loyalty_program_member_number"
            type="text"
            placeholder="Mitgliedsnummer"
            classes="h-12 text-g7-blue"
            name="loyalty"
            class="w-full border border-g7-blue md:w-2/3 md:border-none md:pl-8"
            @update:model-value="
              update('loyalty_program_member_number', $event)
            "
          />
          <input
            :model-value="modelValue.loyalty_program_member"
            type="checkbox"
            class="ml-4 mr-2.5 size-4 accent-g7-blue lg:size-8"
            @input="
              update(
                'loyalty_program_member',
                !modelValue.loyalty_program_member
              )
            "
          />
        </div>
        <FieldDropdownByValue
          v-if="options.cabin_types_select.options"
          :model-value="modelValue.cabin_type"
          :options="options.cabin_types_select.options"
          class="my-1 w-full whitespace-nowrap bg-white sm:ml-4 sm:w-fit lg:my-0 lg:ml-4"
          @update:model-value="update('cabin_type', $event)"
        />
      </div>
      <G7Button
        class="my-1 h-field w-full p-2 text-xs sm:ml-4 sm:w-auto sm:text-sm md:text-base"
        color="orange"
        :pending="creatingOrder"
        :disabled="creatingOrder"
        label="Angebot anzeigen"
        @click="emit('init')"
      />
    </div>
  </div>
</template>

<script lang="ts" setup>
import type { Ref } from "vue";
import { string } from "yup";
import type { CruisePriceOptions } from "~~/types/cruise";
import type { CreateOrderOccupancy } from "~~/types/order";

const props = defineProps<{
  options: CruisePriceOptions;
  modelValue: CreateOrderOccupancy;
  creatingOrder: boolean;
}>();

const visibleChildren: Ref<0 | 1 | 2> = ref(0);
const schema = string().notOneOf(["0"]);
const hasErrors = ref(false);

function showChilds(value) {
  visibleChildren.value += value;
  updateChildren(visibleChildren.value - 1, "0");
}
const emit = defineEmits<{
  (e: "init"): void;
  (e: "update:modelValue", value: CreateOrderOccupancy): void;
}>();

function update(key: string, value: boolean | string) {
  emit("update:modelValue", { ...props.modelValue, ...{ [key]: value } });
}
async function updateChildren(index: number, value: string) {
  const theModel = { ...props.modelValue };
  theModel.children[index] = value;

  if (visibleChildren.value !== 0) {
    hasErrors.value = !(await schema.isValid(theModel.children[index]));
  } else {
    hasErrors.value = false;
  }

  emit("update:modelValue", theModel);
}
</script>
