<template>
  <div>
    <CruiseHead
      :laf="laf"
      :item="detail.cruise_details"
      @toggle-modal="openItineraryModal = !openItineraryModal"
      @init="initBooking"
    >
      <Occupancy
        id="occupancy"
        v-model="occupancy"
        :creating-order="creatingOrder"
        class="m-4 xl:block"
        :options="detail.cruise_price_filter"
        @init="initBooking"
      />
    </CruiseHead>

    <Occupancy
      id="occupancy"
      v-model="occupancy"
      :creating-order="creatingOrder"
      class="m-5 xl:hidden"
      :options="detail.cruise_price_filter"
      @init="initBooking"
    />

    <hr
      v-if="promotions?.promotions.length === 0"
      class="hidden border-2 drop-shadow-lg lg:block"
    />

    <div v-if="promotions?.promotions.length > 0">
      <CruisePromotion
        v-for="(item, index) in promotions.promotions"
        :key="index"
        :promotion="item"
      />
    </div>

    <CruiseTabs
      id="cruisetabs"
      :tabs="detail.cruise_details.tabs"
      :links="links"
      :cruiseid="detail.cruise_id"
      :bookmarked="detail.bookmarked"
      :share-data="shareData"
      @toggle-modal="openRecommendModal = !openRecommendModal"
    />
    <RecommendModal
      v-if="openRecommendModal"
      :cruiseid="detail.cruise_id"
      @toggle-modal="openRecommendModal = !openRecommendModal"
    />
    <CruiseItineraryModal
      v-if="openItineraryModal"
      :itinerary="detail?.cruise_details.itinerary"
      @toggle-modal="openItineraryModal = !openItineraryModal"
    />
    <hr class="hidden border-2 lg:block" />

    <CruiseFurtherDates
      v-if="
        similarCruises && (cruiseTab === 'ITINERARY' || cruiseTab === 'ROUTE')
      "
      class="m-4"
      :next-cruise="detail.cruise_details.next_cruise"
      :prev-cruise="detail.cruise_details.previous_cruise"
      :cruises="similarCruises"
      :cruiseid="detail.cruise_id"
      :bookmarked="detail.bookmarked"
      :share-data="shareData"
      @toggle-modal="openRecommendModal = !openRecommendModal"
    />

    <CardGroup
      v-if="
        services?.included_services.length != 0 &&
        (cruiseTab === 'ITINERARY' || cruiseTab === 'INCLUDED_SERVICES')
      "
      v-slot="{ item }"
      class="hidden lg:block"
      :items="services.included_services"
      :title="services.title"
    >
      <CruiseService :item="item" />
    </CardGroup>
  </div>
</template>

<script setup lang="ts">
import type { Ref } from "vue";
import type { CreateOrder, CreateOrderOccupancy, Price } from "@/types/order";
import type {
  CruiseDetailResponse,
  CreateOrderResponse,
  CruiseServiceFeaturedResponse,
  CruisePromotionsResponse,
} from "@/types/response";
import type { Links } from "~~/types/layout";
import { CruiseLafEnum, type CruiseLaf } from "~~/types/cruise";
import type { Share } from "~/types/request";

const props = defineProps<{
  links: Links;
}>();

const creatingOrder: Ref<boolean> = ref(false);

const { data: detail, error } = await useGo7seasApi<CruiseDetailResponse>(
  props.links.self[0].href
);

if (error.value) {
  throw createError({ ...error.value, fatal: true });
}

updateDefaultLayout(detail.value);

const openRecommendModal: Ref<boolean> = ref(false);
const openItineraryModal: Ref<boolean> = ref(false);

const occupancy: Ref<CreateOrderOccupancy> = ref({
  adults: detail.value.cruise_price_filter.adults_select.value,
  children: [
    detail.value.cruise_price_filter.first_child_age_select.options[0].value,
    detail.value.cruise_price_filter.second_child_age_select.options[0].value,
  ],
  cabin_type:
    detail.value.cruise_price_filter.cabin_types_select.options[0].value,
});

if (detail.value.cruise_price_filter.loyalty_program_member_checkbox) {
  occupancy.value.loyalty_program_member =
    detail.value.cruise_price_filter.loyalty_program_member_checkbox.checked;
  occupancy.value.loyalty_program_name =
    detail.value.cruise_price_filter.loyalty_program_member_checkbox.label;
  occupancy.value.loyalty_program_member_number = "";
}

const cruiseTab = computed(() => useState("cruiseTab").value);

const createOrder: Ref<CreateOrder> = computed(() => ({
  occupancy: occupancy.value,
  cruise_id: detail.value.cruise_id,
}));

const shareData: Ref<Share> = computed(() => {
  return {
    title:
      "Kreuzfahrten, Flüge & Hotels suchen und vergleichen bei www.go7seas-kreuzfahrten.de",
    text: detail.value?.meta_tags?.find((item) => item.property === "og:title")
      ?.content,
    url: detail.value?.meta_tags?.find((item) => item.property === "og:url")
      ?.content,
  };
});

const similarCruises = ref();

try {
  similarCruises.value = await useGo7seasClient(
    "/catalog/cruises/similar-cruises",
    {
      method: "POST",
      body: {
        cruise_id: detail.value.cruise_id,
        occupancy: {
          adults: 2,
          children: [],
        },
      },
    }
  );
} catch (error) {
  similarCruises.value = undefined;
}

const { data: services } = await useGo7seasApi<CruiseServiceFeaturedResponse>(
  `/catalog/cruises/${detail.value.cruise_id}/included-services-featured`
);

const { data: promotions } = await useGo7seasApi<CruisePromotionsResponse>(
  `/catalog/cruises/${detail.value.cruise_id}/promotions`
);

const laf = computed<CruiseLaf>(() => {
  const defaultPrice: Price = {
    value: -1,
    currency: "",
    formatted: "ausgeb.",
    context: "",
  };

  const defaultLaf: CruiseLaf = {
    amount: defaultPrice,
    cabin_type: CruiseLafEnum.INTERIOR,
  };

  const cruiseLafArray = detail.value?.cruise_details?.cruise_laf;

  if (cruiseLafArray && cruiseLafArray.length > 0) {
    const foundLaf = cruiseLafArray.find(
      (item) => item.cabin_type === occupancy.value.cabin_type
    );
    return foundLaf ?? defaultLaf;
  }

  return defaultLaf;
});

function initBooking() {
  localStorage.setItem("cruise_id", detail.value.cruise_id);
  creatingOrder.value = true;

  useGo7seasClient<CreateOrderResponse>("/orders", {
    method: "POST",
    body: createOrder.value,
  })
    .then((response) => {
      useBooking().value = response;
      navigateTo("/auswahl/kategorien");
    })
    .finally(() => {
      creatingOrder.value = false;
    });
}
</script>
