<template>
  <div class="text-g7-blue">
    <HomeHeroSlider class="mt-2 sm:mt-4 lg:mt-auto" />

    <Headline
      v-if="response"
      :h="1"
      class="m-4 text-center font-bold sm:text-left"
      size="3XL"
      :title="response.page_title"
    />

    <hr class="border-2 drop-shadow-lg" />

    <div class="flex flex-col lg:flex-row">
      <div
        v-if="response?.expert"
        class="m-4 flex flex-col items-center lg:order-2 lg:items-start"
      >
        <Headline
          class="mb-2 font-black lg:mb-4"
          :title="response.expert_title ?? ''"
        />
        <ExpertCard :expert="response.expert" />
      </div>
      <div class="m-4 lg:order-1">
        <FieldParagraph
          :text="response?.text ?? ''"
          :title="response?.text_title ?? ''"
        />
      </div>
    </div>

    <hr class="border-2 border-solid border-inherit" />

    <CardGroup
      v-if="response?.sub_cruise_regions_teaser"
      v-slot="{ item }"
      :mobile-slider="true"
      :limit="3"
      :title="response.sub_cruise_regions_title"
      :items="response.sub_cruise_regions_teaser"
      button="Mehr anzeigen"
    >
      <ImgCard class="mx-8 md:mx-0" :item="item" />
    </CardGroup>
    <CardGroup
      v-if="response?.harbours_teaser"
      v-slot="{ item }"
      :limit="3"
      :mobile-slider="true"
      :title="response.harbours_title"
      :items="response.harbours_teaser"
      button="Mehr anzeigen"
    >
      <ImgCard class="mx-8 md:mx-0" :item="item" />
    </CardGroup>

    <CruiseList
      v-if="cruises"
      class="mx-4 mb-8"
      :title="cruises.title"
      :button="cruises.action_button"
      :offers="cruises.cruises"
    />
  </div>
</template>

<script lang="ts" setup>
import type { Links } from "~~/types/layout";
import type { CruiseListResponse, RegionResponse } from "~~/types/response";

const props = defineProps<{
  links: Links;
}>();

const { data: response, error } = await useGo7seasApi<RegionResponse>(
  props.links.self.href
);

if (!error.value) {
  useDiaShow().set(response.value?.dia_show.dias ?? []);
  updateDefaultLayout(response.value);
}

const { data: cruises } = await useGo7seasApi<CruiseListResponse>(
  props.links.cruises.href
);

updateDefaultLayout(response.value);
</script>
